<template>
  <v-container fluid>
    <BaseBreadcrumb
      class="py-2"
      :title="page.title"
      :icon="mdiFileDownloadOutline"
    ></BaseBreadcrumb>

    <v-data-table
      :loading="isTableLoading"
      loading-text="Cargando..."
      :server-items-length="itemsTotalCount"
      :options.sync="tableOptions"
      :headers="tableHeaders"
      item-key="id"
      :items="bulkDownloads"
      sort-by="itemDateTime"
      class="elevation-4"
      :sort-desc="true"
      disable-sort
      :footer-props="{
        'items-per-page-options': [5, 10, 25, 50]
      }"
    >
      <template v-slot:top>
        <v-row no-gutter class="pa-4">
          <v-btn
            :disabled="isTableLoading"
            color="primary"
            @click="showBulkdownloadCreate"
            class="ma-2 btn--expanded"
          >
            <v-icon left>
              {{ mdiPlus }}
            </v-icon>
            Nueva petición
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            :disabled="isTableLoading"
            outlined
            color="primary"
            @click="getItems()"
            class="ma-2 btn--expanded"
          >
            <v-icon left>
              {{ mdiRefresh }}
            </v-icon>
            Refrescar
          </v-btn>
        </v-row>
      </template>

      <template v-slot:[`item.itemDateTime`]="{ item }">
        {{ new Date(item.itemDateTime).toLocaleString() }}
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-icon small class="mr-1" :color="getStatusColor(item.status)">
          {{ mdiCircle }}
        </v-icon>
        {{ getStatusText(item.status) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-on="on"
              v-bind="attrs"
              @click="getBulkdownloadItem(item)"
            >
              <v-icon>
                mdi-eye-outline
              </v-icon>
            </v-btn>
          </template>
          <span>Mostrar detalle</span>
        </v-tooltip>

        <v-tooltip bottom v-if="item.status === 300">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="getFileBulkdownloadItem(item)"
            >
              <v-icon>
                mdi-download-outline
              </v-icon>
            </v-btn>
          </template>
          <span>Descargar</span>
        </v-tooltip>
      </template>

      <template v-if="getHistoricBulkdownloadsError" v-slot:no-data>
        <v-btn outlined class="ma-4" color="primary" @click="getItems()">
          Reintentar
        </v-btn>
      </template>

      <template v-slot:footer.prepend>
        <v-row>
          <v-spacer> </v-spacer>
          <v-btn
            icon
            class="mr-4"
            @click="loadFirstsItems"
            :disabled="isTableLoading || tableOptions.page === 1"
          >
            <v-icon>
              {{ mdiChevronDoubleLeft }}
            </v-icon>
          </v-btn>

          <v-btn
            icon
            class="mr-8"
            @click="loadLastsItems"
            :disabled="
              isTableLoading ||
                tableOptions.page * tableOptions.itemsPerPage >= itemsTotalCount
            "
          >
            <v-icon>
              {{ mdiChevronDoubleRight }}
            </v-icon>
          </v-btn>
        </v-row>
      </template>
    </v-data-table>

    <v-dialog scrollable v-model="isDownloading" persistent max-width="344">
      <Loading
        :visible="isDownloading"
        :message="'Descargando documentos...'"
      />
    </v-dialog>

    <v-dialog scrollable v-model="showBulkdownloadCreateView" max-width="600">
      <v-card>
        <v-card-title class="text-h5">
          {{ showBulkdownloadOnlyRead ? "Informe petición" : "Nueva petición" }}
        </v-card-title>

        <v-card-text>
          <v-form ref="form">
            <v-text-field
              v-model="computedBulkDownloadRequest.name"
              :disabled="showBulkdownloadOnlyRead"
              :rules="fieldRequerired"
              label="Nombre de la descarga*"
              class="mt-4"
              outlined
              dense
            ></v-text-field>
            <v-menu
              ref="filteringDatesMenu"
              v-model="filteringDatesMenu"
              :close-on-content-click="false"
              :return-value.sync="itemFilteringDates"
              transition="scale-transition"
              offset-y
              :disabled="showBulkdownloadOnlyRead"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :class="{
                    'cursor-default-input': showBulkdownloadOnlyRead,
                    'mt-2': true
                  }"
                  :value="itemFilteringDatesText"
                  :label="dateRangeLabel"
                  prepend-inner-icon="mdi-calendar"
                  :disabled="showBulkdownloadOnlyRead"
                  readonly
                  v-bind="attrs"
                  outlined
                  clearable
                  dense
                  @click:clear="clearFilteringDates"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                :allowed-dates="allowedItemFilteringDates"
                v-model="itemFilteringDates"
                :disabled="showBulkdownloadOnlyRead"
                range
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="filteringDatesMenu = false">
                  Cancelar
                </v-btn>
                <v-btn
                  v-if="itemFilteringDates"
                  text
                  color="primary"
                  :disabled="itemFilteringDates.length < 2"
                  @click="updateDates"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
            <v-select
              class="mt-4"
              multiple
              :disabled="showBulkdownloadOnlyRead"
              v-model="computedBulkDownloadRequest.filter.status"
              :items="[
                { value: 300, text: 'Completado' },
                { value: 400, text: 'Cancelado' },
                { value: 500, text: 'Rechazado' },
                { value: 600, text: 'Eliminado' },
                { value: 700, text: 'Expirado' },
                { value: 800, text: 'Erróneo' }
              ]"
              label="Códigos de estado de sobre"
              outlined
              dense
            ></v-select>

            <v-row>
              <v-col class="py-0 mb-3">
                <v-text-field
                  class="mt-4 col-6"
                  v-model.number="computedBulkDownloadRequest.filter.limit"
                  label="Número máximo de elementos a devolver"
                  :disabled="showBulkdownloadOnlyRead"
                  type="number"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col class="py-0">
                <v-text-field
                  class="mt-4 col-6"
                  :disabled="showBulkdownloadOnlyRead"
                  v-model="computedBulkDownloadRequest.filter.reference"
                  label="Referencia externa"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-checkbox
              class="mt-4 mb-4"
              :disabled="showBulkdownloadOnlyRead"
              v-model="
                computedBulkDownloadRequest.filter.documentSetsNotDownloaded
              "
              label="Descargar solo nuevos documentos"
            >
            </v-checkbox>
            <label style="font-size: 1rem"
              >Añadir documento de evidencias:</label
            >
            <v-checkbox
              class="ml-5 mt-5"
              :disabled="showBulkdownloadOnlyRead"
              v-model="computedBulkDownloadRequest.filter.evidencesPDF"
              label="En formato pdf"
            >
            </v-checkbox>
            <v-checkbox
              class="ml-5 mt-1"
              :disabled="showBulkdownloadOnlyRead"
              v-model="computedBulkDownloadRequest.filter.legalAuditTrail"
              label="En formato json ( estándar jwt )"
            >
            </v-checkbox>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            @click="showBulkdownloadCreateView = false"
            text
            color="primary"
          >
            Cerrar
          </v-btn>

          <v-btn
            v-if="!showBulkdownloadOnlyRead"
            :loading="loadingCreateBulkDownload"
            text
            :disabled="!bulkDownloadRequest.name"
            color="primary"
            @click="createBulkdownloadItem"
          >
            Crear
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog scrollable v-model="showBulkdownloadDetailView" width="auto">
      <Loading
        :visible="!selectedBulkdownload.bulkDownloadId"
        :message="'Recuperando detalle...'"
      />
      <v-card v-if="selectedBulkdownload.bulkDownloadId">
        <v-card-title class="text-h5">
          Ficha detalle
        </v-card-title>

        <v-card-text>
          <h4>Id de la descarga</h4>
          <p>
            {{ selectedBulkdownload.bulkDownloadId }}
          </p>

          <h4>Estado de la descarga</h4>
          <p>
            <v-icon
              small
              class="mr-1 pb-1"
              :color="getStatusColor(selectedBulkdownload.status)"
            >
              {{ mdiCircle }}
            </v-icon>
            <span>{{ getStatusText(selectedBulkdownload.status) }}</span>
          </p>

          <h4>Nombre de la descarga</h4>
          <p>
            {{ selectedBulkdownload.name }}
          </p>

          <h4>Fecha de creación de la descarga</h4>
          <p>
            {{
              new Date(selectedBulkdownload.creationDateTime).toLocaleString()
            }}
          </p>
        </v-card-text>

        <v-card-actions>
          <v-btn
            @click="
              computedBulkDownloadRequest = selectedBulkdownload;
              showBulkdownloadCreateView = true;
              showBulkdownloadDetailView = false;
              showBulkdownloadOnlyRead = true;
            "
            :disabled="updatingSeeDetail"
            text
            color="primary"
          >
            Ver informe
          </v-btn>

          <v-spacer> </v-spacer>
          <v-btn
            :disabled="updatingSeeDetail"
            @click="detailRefresh"
            text
            color="primary"
          >
            Refrescar
          </v-btn>

          <v-btn
            @click="showBulkdownloadDetailView = false"
            text
            color="primary"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  mdiStateMachine,
  mdiFileDownloadOutline,
  mdiPlus,
  mdiRefresh,
  mdiCircle,
  mdiChevronDoubleRight,
  mdiChevronDoubleLeft
} from "@mdi/js";
import { DownloadBinaryZip } from "@/utils/utils";
import Loading from "@/components/commons/Loading";
import { SetAddDay } from "../../../utils/utils";

export default {
  name: "BulkDownload",

  components: {
    Loading
  },

  data: () => ({
    page: {
      title: " Descarga masiva"
    },
    tableOptions: {},
    tab: null,
    isDownloading: false,
    dateRangeLabel: "Rango de fechas",
    mdiFileDownloadOutline: mdiFileDownloadOutline,
    mdiPlus: mdiPlus,
    mdiRefresh: mdiRefresh,
    mdiCircle: mdiCircle,
    mdiChevronDoubleRight: mdiChevronDoubleRight,
    mdiChevronDoubleLeft: mdiChevronDoubleLeft,
    documentStatus: [
      { value: 100, text: "Creado" },
      { value: 200, text: "En proceso" },
      { value: 300, text: "Completado" },
      { value: 800, text: "Erróneo" }
    ],
    loadingCreateBulkDownload: false,
    updatingSeeDetail: false,
    menuFrom: false,
    fieldRequerired: [v => !!v || "El campo es requerido"],
    filteringDatesMenu: false,
    itemFilteringDates: [],
    bulkDownloadRequest: null,
    selectedBulkdownload: {},
    selectedFileBulkdownload: null,
    notificacionRules: [v => !!v || String()],
    tableHeaders: [
      { text: "NOMBRE", value: "name" },
      { text: "ESTADO", value: "status" },
      {
        text: "FECHA",
        value: "itemDateTime"
      },
      {
        text: "ACCIONES",
        value: "actions"
      }
    ],
    mdiStateMachine: mdiStateMachine,
    getHistoricBulkdownloadsError: false,
    isTableLoading: false,
    showBulkdownloadCreateView: false,
    showBulkdownloadDetailView: false,
    showBulkdownloadOnlyRead: false,
    menuTo: false
  }),

  computed: {
    itemFilteringDatesText() {
      var itemFilteringDates = [];
      if (this.itemFilteringDates && this.itemFilteringDates.length > 0) {
        for (var i = 0; i < this.itemFilteringDates.length; i++) {
          const [year, month, day] = this.itemFilteringDates[i].split("-");
          itemFilteringDates.push(`${day}/${month}/${year}`);
        }
      }
      return itemFilteringDates.join(" ~ ");
    },
    bulkDownloads() {
      return this.$store.state.bulkdownloads;
    },
    computedBulkDownloadRequest: {
      get() {
        if (!this.bulkDownloadRequest) this.resetSelectedItem();
        return this.bulkDownloadRequest;
      },
      set(value) {
        this.bulkDownloadRequest = value;
      }
    },
    computedBulkDownloadRequestFromDateTime: {
      get() {
        return this.computedBulkDownloadRequest.filter.fromDateTime || String();
      },
      set(value) {
        this.computedBulkDownloadRequest.filter.fromDateTime = value
          ? new Date(value).toLocaleDateString("fr-CA", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit"
            })
          : value;
      }
    },
    computedBulkDownloadRequestToDateTime: {
      get() {
        return this.computedBulkDownloadRequest.filter.toDateTime || String();
      },
      set(value) {
        this.computedBulkDownloadRequest.filter.toDateTime = value
          ? new Date(value).toLocaleDateString("fr-CA", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit"
            })
          : value;
      }
    },
    itemsTotalCount() {
      return this.$store.getters.bulkDownloadItems;
    }
  },

  watch: {
    tableOptions: {
      deep: true,
      handler() {
        this.getItems();
      }
    }
  },

  methods: {
    allowedItemFilteringDates(val) {
      if (this.itemFilteringDates && this.itemFilteringDates.length === 1) {
        return val >= this.itemFilteringDates[0];
      }
      return val;
    },

    clearFilteringDates() {
      this.itemFilteringDates = [];
    },

    updateDates() {
      this.$refs.filteringDatesMenu.save(this.itemFilteringDates);
    },

    loadFirstsItems() {
      if (this.tableOptions.page === 1) {
        this.getItems();
      } else {
        this.tableOptions.page = 1;
      }
    },

    loadLastsItems() {
      let page = Math.ceil(
        this.itemsTotalCount / this.tableOptions.itemsPerPage
      );
      if (this.tableOptions.page !== page) {
        this.tableOptions.page = page;
      } else {
        this.getItems();
      }
    },

    detailRefresh() {
      this.getItems();
      this.updatingSeeDetail = true;
      this.getBulkdownloadItem();
    },

    getStatusColor(status) {
      switch (status) {
        case 100:
          return "#20a9ef";
        case 200:
          return "#20a9ef";
        case 300:
          return "#52bc03";
        case 800:
          return "#e54448";
        default:
          return "#20a9ef";
      }
    },

    getStatusText(status) {
      var statusFound = this.documentStatus.find(element => {
        return element.value == status;
      });
      return statusFound != undefined ? statusFound.text : "";
    },

    showBulkdownloadCreate() {
      this.showBulkdownloadOnlyRead = false;
      this.$refs.form && this.$refs.form.reset();
      this.clearFilteringDates();
      setTimeout(() => {
        this.resetSelectedItem();
        this.showBulkdownloadCreateView = true;
      }, 0);
    },

    getItems() {
      let offset =
        this.tableOptions.itemsPerPage * (this.tableOptions.page - 1);
      let limit = this.tableOptions.itemsPerPage;

      this.isTableLoading = true;
      this.$store
        .dispatch("getBulkdownloads", {
          limit,
          offset
        })
        .then(() => {
          this.getHistoricBulkdownloadsError = false;
        })
        .catch(err => {
          console.log(err);
          this.getHistoricBulkdownloadsError = true;
        })
        .finally(() => {
          this.isTableLoading = false;
        });
    },

    getBulkdownloadItem(item) {
      this.getItems();

      if (item) this.selectedBulkdownload = {};
      this.isTableLoading = true;
      this.showBulkdownloadDetailView = true;

      this.$store
        .dispatch(
          "getBulkdownload",
          item && item.id ? item.id : this.selectedBulkdownload.bulkDownloadId
        )
        .then(data => {
          if (data && data.result && data.result.filter) {
            if (data.result.filter.fromDateTime) {
              data.result.filter.fromDateTime = new Date(
                data.result.filter.fromDateTime
              ).toLocaleDateString("fr-CA", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit"
              });
              this.itemFilteringDates.push(data.result.filter.fromDateTime);
            }
            if (data.result.filter.toDateTime) {
              data.result.filter.toDateTime = new Date(
                data.result.filter.toDateTime
              ).toLocaleDateString("fr-CA", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit"
              });
              this.itemFilteringDates.push(data.result.filter.toDateTime);
            }
          }
          this.selectedBulkdownload = data.result;
          if (!(item && item.id)) {
            this.$toasted.global.toast_success({
              message: `Se ha ${
                item && item.id ? "obtenido" : "actualizado"
              } el detalle correctamente`
            });
          }
        })
        .catch(err => {
          this.showBulkdownloadDetailView = false;
          console.log(err);
          this.$toasted.global.toast_error({
            message: `No se ha podido obtener la petición`
          });
        })
        .finally(() => {
          this.updatingSeeDetail = false;
          this.isTableLoading = false;
        });
    },

    getFileBulkdownloadItem(item) {
      this.isTableLoading = true;
      this.isDownloading = true;

      this.$store
        .dispatch("getFileBulkdownload", item.id)
        .then(data => {
          this.selectedFileBulkdownload = data;
          DownloadBinaryZip(data, item.name);
          this.$toasted.global.toast_success({
            message: `Se han obtenido los documentos correctamente`
          });
        })
        .catch(err => {
          console.log(err);
          this.$toasted.global.toast_error({
            message: `No se ha podido obtener los documentos de la petición`
          });
        })
        .finally(() => {
          this.isTableLoading = false;
          this.isDownloading = false;
        });
    },

    createBulkdownloadItem() {
      this.loadingCreateBulkDownload = true;

      const bulkDownloadRequest = JSON.parse(
        JSON.stringify(this.computedBulkDownloadRequest)
      );

      if (this.itemFilteringDates && this.itemFilteringDates.length === 2) {
        bulkDownloadRequest.filter.fromDateTime = this.itemFilteringDates[0];
        bulkDownloadRequest.filter.toDateTime = SetAddDay(
          this.itemFilteringDates[1]
        );
      }
      this.$store
        .dispatch("postBulkdownload", bulkDownloadRequest)
        .then(() => {
          this.showBulkdownloadCreateView = false;
          this.resetSelectedItem();
          this.getItems();
          this.$toasted.global.toast_success({
            message: `Se ha creado la petición correctamente`
          });
        })
        .catch(err => {
          console.log(err);
          this.$toasted.global.toast_error({
            message: `No se ha podido obtener los detalles de la petición`
          });
        })
        .finally(() => {
          this.loadingCreateBulkDownload = false;
        });
    },
    resetSelectedItem() {
      this.computedBulkDownloadRequest = {
        name: "firmarOnline_descarga_" + new Date().toISOString(),
        filter: {
          limit: 50,
          fromDateTime: void 0,
          toDateTime: void 0,
          status: [],
          reference: void 0,
          evidencesPDF: false,
          legalAuditTrail: true,
          documentSetsNotDownloaded: true
        }
      };
    }
  }
};
</script>
<style scoped>
@media (max-width: 450px) {
  .btn--expanded {
    width: 95%;
  }
}
</style>
